/* src/App.css */
:root {
  --primary-color: #39bb43;
  --secondary-color: #48a04c;
  --text-color: #2c3e50;
  --background-color: #f0fdf4;
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.app-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.header {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 30px;
}

.header h1 {
  margin: 0;
  font-size: 2.5em;
}

.form-container {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(46, 204, 113, 0.1);
}

h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1fae5;
  border-radius: 4px;
  font-size: 16px;
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--secondary-color);
}

.error-message {
  color: #f44336;
  margin-bottom: 15px;
}
