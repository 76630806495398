:root {
  --primary-color: #159e30;
  --primary-hover: #2d794c;
  --error-color: #e74c3c;
  --text-color: #2c3e50;
  --background-color: #ecf0f1;
  --card-background: #ffffff;
  --border-color: #bdc3c7;
}

body {
  background: var(--background-color);
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.page-container {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  background: var(--background-color);
  overflow: hidden;
}

.form-container {
  width: 100%;
  max-width: 440px;
  padding: clamp(24px, 5vw, 40px);
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(16, 185, 129, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: calc(100svh - 40px);
}

.form-container h1 {
  display: none;
}

.form-container h2 {
  text-align: center;
  color: var(--text-color);
  margin: 0 0 clamp(20px, 4vw, 32px) 0;
  padding: 0;
  font-size: clamp(24px, 5vw, 32px);
  font-weight: 800;
  letter-spacing: -0.5px;
  width: 100%;
  line-height: 1.2;
}

.form-container h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: var(--primary-color);
  margin: 16px auto 0;
  border-radius: 2px;
  opacity: 0.7;
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.form-container form div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.form-container label {
  font-size: 14px;
  color: var(--label-color);
  font-weight: 600;
  margin-left: 2px;
}

.form-container input,
.form-container select {
  padding: clamp(12px, 2vw, 14px) clamp(14px, 2vw, 16px);
  border: 2px solid var(--input-border);
  border-radius: 8px;
  font-size: clamp(14px, 2vw, 15px);
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
  background-color: var(--input-background);
  color: var(--input-text);
}

.form-container input::placeholder,
.form-container select::placeholder {
  color: var(--input-placeholder);
}

.form-container input:hover,
.form-container select:hover {
  border-color: var(--primary-color);
}

.form-container input:focus,
.form-container select:focus {
  outline: none;
  border-color: var(--input-focus);
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.15);
  background-color: var(--input-background);
}

.form-container button {
  background-color: var(--primary-color);
  color: white;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
              background-color 0.2s ease;
  isolation: isolate;
  overflow: visible;
  margin: 0;
}

/* Ripple effect on click */
.form-container button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.form-container button:active {
  transform: scale(0.97);
}

.form-container button:active::after {
  width: 200px;
  height: 200px;
  opacity: 0;
  transform: translate(-50%, -50%) scale(5);
  transition: 0s;
}

.form-container button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

/* Remove hover effect when button is disabled or loading */
.form-container button:disabled:hover,
.form-container button.loading:hover {
  transform: none;
  background-color: var(--primary-color);
}

.error-message {
  color: var(--error-color);
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  padding: clamp(10px, 2vw, 12px);
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-size: clamp(13px, 2vw, 14px);
  max-width: 100%;
  box-sizing: border-box;
}

/* Updated loading state for button */
.form-container button.loading {
  position: relative;
  background: var(--primary-color);
  overflow: hidden;
  pointer-events: none;
  transform: translateY(0) !important;
}

.form-container button.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 80%,
    transparent 100%
  );
  animation: shimmer 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(50%);
  }
}

/* Animations */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

/* Custom select styling */
.form-container select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%234b5563' viewBox='0 0 16 16'%3E%3Cpath d='M8 10.5l-4-4h8l-4 4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 40px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .page-container {
    padding: 16px;
    height: 100vh;
    position: fixed;
  }

  .form-container {
    margin: 0;
    width: 100%;
  }

  .form-container form {
    gap: 16px;
  }

  .form-container button {
    padding: 12px;
    font-size: 15px;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #f0fdf4;
    --text-color: #000000;
    --label-color: #047857;
    --input-border: #d1fae5;
    --input-background: #f0fdf4;
    --input-text: #000000;
    --input-placeholder: #6b7280;
  }

  .form-container {
    background: white;
    box-shadow: 0 4px 20px rgba(16, 185, 129, 0.1);
  }

  .form-container input,
  .form-container select {
    background-color: var(--input-background);
    color: var(--input-text);
    border-color: var(--input-border);
  }

  .form-container input::placeholder,
  .form-container select::placeholder {
    color: var(--input-placeholder);
  }

  .form-container input:focus,
  .form-container select:focus {
    background-color: var(--input-background);
    border-color: var(--primary-color);
  }

  /* Update select dropdown arrow color for dark mode */
  .form-container select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%239ca3af' viewBox='0 0 16 16'%3E%3Cpath d='M8 10.5l-4-4h8l-4 4z'/%3E%3C/svg%3E");
  }
}

/* Add cookie consent bar styles */
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  background: rgba(16, 185, 129, 0.95);
  backdrop-filter: blur(8px);
  color: white;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 1000;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.cookie-consent button {
  background-color: white;
  color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  font-size: 14px;
  min-width: fit-content;
  flex-shrink: 0;
  margin: 0;
  line-height: normal;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cookie-consent p {
  margin: 0;
  font-size: 14px;
  max-width: 800px;
  text-align: center;
  padding: 0 8px;
  line-height: 1.4;
}

.cookie-consent button:hover {
  background-color: #f3f4f6;
}

@media (max-width: 480px) {
  .cookie-consent {
    flex-direction: column;
    padding: 12px;
    gap: 8px;
  }
  
  .cookie-consent p {
    font-size: 13px;
    padding: 0;
  }

  .cookie-consent button {
    width: 100%;
    padding: 8px;
    font-size: 13px;
  }
}

/* Add smooth transitions for all interactive elements */
.form-container input,
.form-container select,
.form-container button,
.cookie-consent {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Improved button states */
.form-container button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Update main title styles */
.main-title {
  text-align: center;
  color: var(--primary-color);
  margin: 0 0 40px 0;
  padding: 0;
  font-size: clamp(42px, 8vw, 56px);
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 1.1;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .main-title {
    font-size: clamp(36px, 7vw, 42px);
    margin-bottom: 30px;
  }
}

/* Add styles for the auth check state */
.auth-check-container {
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.loading-spinner {
  width: 32px;
  height: 32px;
  border: 3px solid var(--input-border);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update existing page container styles */
.page-container {
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  animation-delay: 0.1s;
}

/* Add styles for redirect message */
.redirect-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  animation: fadeIn 0.3s ease forwards;
}

.redirect-message p {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}